import { FaRegCopyright } from "react-icons/fa6";
import { TextFooter } from "./styles";

export default function Footer(){

  return(

    <footer className="row py-3">

      <section className="col-12 text-center">

        <TextFooter>
          <FaRegCopyright /> &nbsp; Todos os direitos reservados - <span>Noobiel Dev</span>
        </TextFooter>

      </section>

    </footer>

  )

}
