import styled from "styled-components";

export const Title = styled.h2`

  color: ${({theme})=>theme.colors.purple};
  font-size: ${({theme})=>theme.fontSize["2xl"]};
  font-weight: 700;
  margin-bottom: 1rem;

  @media screen and (max-width: ${({theme})=>theme.breakPoints.xs}) {

    font-size: ${({theme})=>theme.fontSize.lg};

  }

  @media screen and (max-width: ${({theme})=>theme.breakPoints.sm}) {

    font-size: ${({theme})=>theme.fontSize.xl};

  }

  @media screen and (max-width: ${({theme})=>theme.breakPoints.md}) {

    font-size: ${({theme})=>theme.fontSize.xl};

  }

`;

export const SubTitle = styled.h3`

  color: ${({theme})=>theme.colors.black};
  font-size: ${({theme})=>theme.fontSize["4xl"]};
  font-weight: 700;

  @media screen and (max-width: ${({theme})=>theme.breakPoints.xs}) {

    font-size: ${({theme})=>theme.fontSize["2xl"]};

  }

  @media screen and (max-width: ${({theme})=>theme.breakPoints.md}) {

    font-size: ${({theme})=>theme.fontSize["3xl"]};

  }

`;
