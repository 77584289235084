import { Title, SubTitle } from "./styles";

interface TitleSectionProps{

  title: string
  subTitle: string

}

export function TitleSection({title, subTitle}: TitleSectionProps){

  return(

    <>

      <Title>{title}</Title>
      <SubTitle>{subTitle}</SubTitle>

    </>

  )

}
