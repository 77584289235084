import Logo from '../../img/logos/logo_icone_final.png';

import { FaBarsStaggered } from "react-icons/fa6";

import { scrollToSection } from '../../helpers/ScrollToSection';

import { useNavigate } from 'react-router-dom';

export default function Navbar(){

  const navigate = useNavigate();

  const handleNavigation = (path: string, id?: string) => {

    navigate(path);

    if (id) {

      setTimeout(() => {
        scrollToSection(id);
      }, 100);

    };
  }

  return(

    <nav className="navbar bgWhite navbar-expand-md fixed-top">
      <div className="container">
        <a className="navbar-brand"><img src={Logo} alt="Logo Noobiel" width={30} /></a>
        <button className="navbar-toggler border-0" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar" aria-label="Toggle navigation">
          <FaBarsStaggered className='purple' size={22} />
        </button>
        <div className="offcanvas offcanvas-end" tabIndex={-1} id="offcanvasNavbar" aria-labelledby="offcanvasNavbarLabel">
          <div className="offcanvas-header">
            <h5 className="offcanvas-title" id="offcanvasNavbarLabel">Menu</h5>
            <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
          </div>
          <div className="offcanvas-body">
            <ul className="navbar-nav justify-content-end flex-grow-1 pe-3">
              <li className="nav-item">
                <a className="nav-link" aria-current="page" onClick={() => handleNavigation('/', 'home')} data-bs-dismiss="offcanvas">Início</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" onClick={() => handleNavigation('/portfolio')} data-bs-dismiss="offcanvas">Portfólio</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" onClick={() => handleNavigation('/', 'results')} data-bs-dismiss="offcanvas">Resultados</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" onClick={() => handleNavigation('/', 'services')} data-bs-dismiss="offcanvas">Serviços</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" onClick={() => handleNavigation('/', 'projects')} data-bs-dismiss="offcanvas">Projetos</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" onClick={() => handleNavigation('/', 'aboutUs')} data-bs-dismiss="offcanvas">Sobre mim</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" onClick={() => handleNavigation('/', 'contact')} data-bs-dismiss="offcanvas">Contato</a>
              </li>

            </ul>
          </div>
        </div>
      </div>
    </nav>

  )

}
